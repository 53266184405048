.blinking {
    margin-left: 15px;
    display: flex;
    align-items: baseline;
    flex-grow: 1;
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.dot {
    background-color: rgb(221, 0, 0);
    border-radius: 50%;
    height: 10px;
    width: 9px;
    margin-right: 5px;
}

@keyframes blink {
    0%   {opacity: 1;}
    50%  {opacity: 0;}
    100% {opacity: 1;}
}

.headerLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
}